import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import logo from "../../../../../assets/images/ivp-logo.svg";

export const FrontEndHeader = ({ expiration, isLoading, contactRef }) => {
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (contactRef.current) {
      setRef(contactRef);
    }
    setRef(contactRef.current);
  }, [contactRef]);

  if (!isLoading) {
    let expirationTime;
    if (expiration) {
      expirationTime = moment(expiration).diff(moment(), "days");
    }
    return (
      <header className="presentation-header">
        <Navbar bg="secondaryBackground" variant="dark">
          <Container fluid className="d-flex flex-column flex-sm-row">
            <Navbar.Brand className="text-white">
              <img src={logo} height="80px" width="auto" />{" "}
            </Navbar.Brand>
            <Navbar.Text className="text-center d-flex align-items-center">
              {expiration && (
                <h4 className="me-3 text-white mb-0">{`Page expires in ${expirationTime}`}</h4>
              )}
              {ref && (
                <a className="btn btn-brand-1" href="#contact-information">
                  GET IN TOUCH
                </a>
              )}
            </Navbar.Text>
          </Container>
        </Navbar>
      </header>
    );
  }
};
