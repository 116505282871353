import React from "react";
import dompurify from "dompurify";
import { Col, Figure, Row } from "react-bootstrap";

export const ClientInfo = ({ data, title }) => {
  const sanitizer = dompurify.sanitize;

  if (data) {
    return (
      <div className="frontend-client-information">
        <Row className="align-items-center">
          {data.clientImage.length > 0 && (
            <Col md={3} className="client-information-image">
              <Figure>
                <Figure.Image
                  width="100%"
                  height="auto"
                  src={data.clientImage[0].fileUrl[0]}
                  alt={data.clientImage[0].fileAlt}
                ></Figure.Image>
              </Figure>
            </Col>
          )}
          <Col
            className={`client-information-details ${
              data.clientImage ? "details-border" : ""
            }`}
          >
            <div>
              <h1>{title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(data.clientNote),
                }}
              ></div>
              {data.clientSignature && (
                <div className="signature">
                  <p>
                    <strong>Bob Labanara</strong>
                  </p>
                  <p>CEO &amp; President</p>
                  <div>
                    <a className="text-brand-1" href="tel:203.710.0491">
                      203.710.0491
                    </a>
                  </div>
                  <div>
                    <a
                      className="text-brand-1"
                      href="mailto:BobLabanara@gmail.com"
                    >
                      BobLabanara@gmail.com
                    </a>
                  </div>
                  <div>
                    <a
                      className="text-brand-1"
                      href="https://www.linkedin.com/company/island-view-partners/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      linkedin.com/company/island-view-partners
                    </a>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  } else {
    return null;
  }
};
